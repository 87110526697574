import { MatMenuTrigger } from "@angular/material/menu";
import { featherBookOpen } from "@ng-icons/feather-icons";
import { tablerDragDrop } from "@ng-icons/tabler-icons";
import { BaseControl, BaseControlType } from "src/app/core/helper/rete/basecontrol";
import { BaseInput } from "src/app/core/helper/rete/baseinput";
import { BaseNode } from "src/app/core/helper/rete/basenode";
import { BaseOutput } from "src/app/core/helper/rete/baseoutput";
import { GatewayService } from "src/app/core/services/gateway.service";
import { GraphService } from "src/app/core/services/graph.service";
import { NotificationService, NotificationType } from "src/app/core/services/notification.service";
import { RegistryService } from "src/app/core/services/registry.service";
import { ReteService } from "src/app/core/services/rete.service";
import { GraphModel } from "src/app/schemas/model";
import { Permission } from "src/app/schemas/graph";
import {
  Component,
  Input as _Input,
  HostBinding,
  ChangeDetectorRef,
  OnChanges,
  inject,
  HostListener,
  ViewChild,
  OnInit,
  ElementRef,
  AfterViewInit,
} from "@angular/core";

import tippy, { Instance, Props } from "tippy.js";
import logos from "../../../assets/logos.json";

const DEFAULT_HEADER_COLOR = "linear-gradient(to right, rgb(34, 118, 197), rgb(21, 67, 128))"
const DEFAULT_BODY_COLOR = "linear-gradient(to right, rgb(50, 120, 200), rgb(10, 50, 128))"

// Constants for Header Background. The colors are defined here:
// https://docs.github.com/en/actions/creating-actions/metadata-syntax-for-github-actions#brandingcolor
const HEADER_BACKGROUND_COLORS = new Map<string, string>([
  ["white", "white"],
  ["yellow", "linear-gradient(to right, #996633 0%, #ff9900 100%)"],
  ["blue", "linear-gradient(to right, #333399 0%, #0066ff 100%)"],
  ["green", "linear-gradient(135deg, rgba(5, 171, 18, 1.0), rgba(2, 64, 16, 1.0))"],
  ["orange", "linear-gradient(to right, #cc6600 0%, #996633 100%)"],
  ["red", "linear-gradient(to right, #990000 0%, #cc3300 100%)"],
  ["purple", "linear-gradient(to right, #660033 0%, #660066 100%)"],
  ["gray-dark", "#303030"]
]);

// Constants for Body Background. The colors are defined here:
// https://docs.github.com/en/actions/creating-actions/metadata-syntax-for-github-actions#brandingcolor
const BODY_BACKGROUND_COLORS = new Map<string, string>([
  ["white", "white"],
  ["yellow", "linear-gradient(to right, #ff9933 0%, #996600 100%)"],
  ["blue", "linear-gradient(to right, #003366 0%, #0066cc 100%)"],
  ["green", "linear-gradient(135deg, rgba(5, 171, 18, 1.0), rgba(2, 64, 16, 1.0))"],
  ["orange", "linear-gradient(to right, #cc6600 0%, #996600 100%)"],
  ["red", "linear-gradient(to right, #990000 0%, #cc3300 100%)"],
  ["purple", "linear-gradient(to right, #660066 0%, #660033 100%)"],
  ["gray-dark", "#404040"]
]);

import {
  MatDialog,
} from "@angular/material/dialog";
import { GroupNodeInfoComponent, GroupNodeInfoData } from "../groupnode-info/groupnode-info.component";
import { getErrorMessage, htmlIsUserInputField, isEmbedded, openDocs } from "src/app/core/helper/utils";
import { getGhActionIcon } from "src/app/core/helper/gh-icons";
import { NgxTippyService } from "ngx-tippy-wrapper";
import { NodeCommentComponent, NodeCommentData } from "../node-comment/node-comment.component";

interface Icon {
  title: string;
  category: string | string[];
  route: { dark: string } | string;
  url: string;
}

function isDarkRoute(route: { dark: string } | string): route is { dark: string } {
  return typeof route === "object" && "dark" in route;
}

@Component({
  templateUrl: "./basenode.component.html",
  styleUrls: ["./basenode.component.scss"],
  selector: "app-basenode",
})
export class BaseNodeComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild("menuTrigger", { read: MatMenuTrigger }) contextMenu: MatMenuTrigger | null = null;
  @ViewChild("comment") comment!: ElementRef;

  cdr = inject(ChangeDetectorRef);
  nr = inject(RegistryService);
  gs = inject(GraphService);
  rs = inject(ReteService);
  gw = inject(GatewayService);
  ns = inject(NotificationService);
  dialog = inject(MatDialog);
  host = inject(ElementRef);
  ts = inject(NgxTippyService);

  @_Input() data!: BaseNode;
  @_Input() emit!: (data: unknown) => void;
  @_Input() rendered!: () => void;

  featherBookOpen = featherBookOpen;
  tablerDragDrop = tablerDragDrop;

  tsInstance: Instance<Props> | null = null;

  public svgLogo: string | undefined = undefined;
  public icon: string | undefined = undefined;

  public seed = 0;
  public mouseover = false;
  public groupInputOutput = false;
  public ghActionTitle?: string;
  public error?: string;

  isEmbedded = isEmbedded;
  Permission = Permission

  constructor() {
    this.cdr.detach();
  }

  ngAfterViewInit(): void {
    if (this.data.getComment()) {
      // Without setTimeout, the tooltip is not positioned correctly
      setTimeout(() => {
        this.initComment();
      })
    }
  }

  initComment(): void {
    if (this.tsInstance) {
      this.tsInstance.destroy();
      this.tsInstance = null;
    }

    if (this.data.getComment() &&
      this.host.nativeElement.parentNode /* in some unknown cases, the node is not yet attached to the DOM? */) {
      this.tsInstance = tippy(this.host.nativeElement, {
        content: this.data.getComment(),
        interactive: true,
        showOnCreate: true,
        hideOnClick: false,
        zIndex: 99, // below the hover buttons (which is z-[100])
        placement: "top",
        trigger: "manual",
      }) as unknown as Instance<Props>;
      this.tsInstance!.show();
    }
  }

  ngOnInit(): void {
    if (this.data.getNodeType().startsWith("github.com/")) {
      this.ghActionTitle = this.data.getDefinition().id.split("/", 2)[1]
    }

    this.error = this.data.getDefinition().error || "";

    this.groupInputOutput = this.isGroupInputNode() || this.isGroupOutputNode();
    const icon = this.data.getNodeModel().icon;
    if (icon) {
      if (Object.hasOwnProperty.call(logos, icon)) {
        const logo = logos[icon as keyof typeof logos] as Icon;
        this.svgLogo = `assets/logos/${isDarkRoute(logo.route) ? logo.route.dark : logo.route}`;
      }
    }

    if (!this.svgLogo) {
      if (icon) {
        this.icon = icon;
      } else {
        const defIcon = this.data.getDefinition().icon;
        if (defIcon) {
          this.icon = getGhActionIcon(defIcon)
        }
      }
    }
    this.cdr.detectChanges();
  }

  ngOnChanges(): void {
    this.cdr.detectChanges();
    requestAnimationFrame(() => this.rendered());
    this.seed++; // force render sockets
  }

  isValid(): boolean {
    // By current definition, a node is valid if it has a name.
    // This is set by the gateway when nodes are requested via /full endpoint.
    return Boolean(this.data.getDefinition().name) && !this.data.getDefinition().error;
  }

  onOpenDocs(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    openDocs(this.data.getDefinition());
  }

  async onFoldToggle(event: MouseEvent): Promise<void> {
    event.stopPropagation();
    event.preventDefault();

    const n = this.rs.getEditor().getNode(this.data.id);
    if (n) {
      n.getSettings().folded = !n.getSettings().folded;
    }

    this.contextMenu?.closeMenu();

    await this.rs.getArea().update("node", this.data.id);
    this.cdr.detectChanges();
  }

  async onCopyNode(event: MouseEvent): Promise<void> {
    event.stopPropagation();
    event.preventDefault();

    try {
      await this.gs.copyToClipboard(new Set([this.data.id]));

      this.ns.showNotification(NotificationType.Success, "node copied to clipboard");
    } catch {
      this.ns.showNotification(NotificationType.Error, "failed to copy node to clipboard");
    } finally {
      this.contextMenu?.closeMenu();
    }
  }

  async onDeleteNode(event: MouseEvent): Promise<void> {
    event.stopPropagation();
    event.preventDefault();

    if (this.getPermission() !== Permission.Writable) {
      throw new Error("graph is not writable");
    }

    if (this.data.getDefinition().entry) {
      // entry nodes cannot be deleted
      return;
    }

    try {
      await this.gs.deleteNodes(new Set([this.data.id]));
      this.ns.showNotification(NotificationType.Success, "node deleted");
    } catch {
      this.ns.showNotification(NotificationType.Error, "failed to delete node");
    } finally {
      this.contextMenu?.closeMenu();
    }
  }

  async shareNodes(shortLink: boolean, graph: GraphModel, nodeIds: string[]): Promise<void> {
    const targetUrl = await this.gs.shareNodes(shortLink, graph, nodeIds);
    if (targetUrl) {
      try {
        await navigator.clipboard.writeText(targetUrl);
        this.ns.showNotification(NotificationType.Success, "graph url copied to clipboard");
      } catch (error) {
        this.ns.showNotification(NotificationType.Error, getErrorMessage(error));
      }
    }
  }

  async onShareNode(event: MouseEvent): Promise<void> {
    event.stopPropagation();
    event.preventDefault();

    const current = this.gs.getCurrentGraph();
    if (!current) {
      throw new Error("expected current graph");
    }

    try {
      await this.shareNodes(!(event.metaKey || event.ctrlKey), current, Array.from([this.data.id]));
      // no notification needed here
    } catch (e) {
      this.ns.showNotification(NotificationType.Error, getErrorMessage(e));
    } finally {
      this.contextMenu?.closeMenu();
    }
  }

  async onShareNodes(event: MouseEvent): Promise<void> {
    event.stopPropagation();
    event.preventDefault();

    const current = this.gs.getCurrentGraph();
    if (!current) {
      throw new Error("expected current graph");
    }

    try {
      await this.shareNodes(!(event.metaKey || event.ctrlKey), current, Array.from(this.gs.getSelectedNodes().keys()));
      // no notification needed here
    } catch (e) {
      this.ns.showNotification(NotificationType.Error, getErrorMessage(e));
    } finally {
      this.contextMenu?.closeMenu();
    }
  }

  async onCopyNodes(event: MouseEvent): Promise<void> {
    event.stopPropagation();
    event.preventDefault();

    try {
      const nodeIds = new Set(this.gs.getSelectedNodes().keys());
      const copied = await this.gs.copyToClipboard(nodeIds);
      this.ns.showNotification(NotificationType.Success, `${copied} nodes copied to clipboard`);
    } catch (e) {
      this.ns.showNotification(NotificationType.Error, getErrorMessage(e));
    } finally {
      this.contextMenu?.closeMenu();
    }
  }

  async onOpenGroupNode(event: MouseEvent): Promise<void> {
    event.stopPropagation();
    event.preventDefault();

    try {
      await this.gs.goDownGraphHierarchy(this.data.id);
      // no notification needed here
    } catch (e) {
      this.ns.showNotification(NotificationType.Error, getErrorMessage(e));
    } finally {
      this.contextMenu?.closeMenu();
    }
  }

  async onAddIndexOutput(event: MouseEvent, output: BaseOutput): Promise<void> {
    event.stopPropagation();
    event.preventDefault();

    try {
      this.data.addIndexOutputTo(output);
    } catch (e) {
      this.ns.showNotification(NotificationType.Error, getErrorMessage(e));
    }

    await this.rs.getArea().update("node", this.data.id);
    this.cdr.detectChanges();
  }

  async onAddIndexInput(event: MouseEvent, input: BaseInput): Promise<void> {
    event.stopPropagation();
    event.preventDefault();

    try {
      this.data.addIndexInputTo(input);
    } catch (e) {
      this.ns.showNotification(NotificationType.Error, getErrorMessage(e));
    }

    const control = input.control as BaseControl<BaseControlType> | null;
    if (control) {
      await this.rs.getArea().update("control", control.id);
    }
    await this.rs.getArea().update("node", this.data.id);
    this.cdr.detectChanges();
  }

  async onPopIndexOutput(event: MouseEvent, output: BaseOutput): Promise<void> {
    event.stopPropagation();
    event.preventDefault();

    await this.data.popIndexOutput(output, async (affectedOutputId: string) => {
      const editor = this.rs.getEditor();
      for (const conn of editor.getConnections()) {
        if (conn.source === this.data.id && conn.sourceOutput == affectedOutputId) {
          await editor.removeConnection(conn.id);
          break;
        }
      }
    });
    await this.rs.getArea().update("node", this.data.id);
    this.cdr.detectChanges();
  }

  async onPopIndexInput(event: MouseEvent, input: BaseInput): Promise<void> {
    event.stopPropagation();
    event.preventDefault();

    await this.data.popIndexInput(input, async (affectedInputId: string) => {
      const editor = this.rs.getEditor();
      for (const conn of editor.getConnections()) {
        if (conn.target === this.data.id && conn.targetInput == affectedInputId) {
          await editor.removeConnection(conn.id);
          break;
        }
      }
    });

    const control = input.control as BaseControl<BaseControlType> | null;
    if (control) {
      await this.rs.getArea().update("control", control.id);
    }
    await this.rs.getArea().update("node", this.data.id);
    this.cdr.detectChanges();
  }

  @HostBinding("class.selected")
  get selected(): boolean {
    return Boolean(this.data.selected);
  }

  isGroupInputNode(): boolean {
    return this.data.getNodeType().startsWith("group-inputs@")
  }

  isGroupOutputNode(): boolean {
    return this.data.getNodeType().startsWith("group-outputs@")
  }

  isGroupNode(): boolean {
    return !!this.data.getNodeModel().graph;
  }

  getSelectedNodesCount(): number {
    return this.gs.getSelectedNodes().size;
  }

  onRightClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    if (!event.ctrlKey) {
      this.contextMenu?.openMenu();
      this.contextMenu?.updatePosition();
    }
  }

  onRemovePort(event: MouseEvent, node: BaseNode, port: BaseOutput | BaseInput): void {
    event.stopPropagation();
    event.preventDefault();

    void this.gs.removePort(node, port)
      .then(() => {
        this.cdr.detectChanges();
      });
  }

  getFoldIcon(): string {
    return this.data.getSettings().folded ? "tablerArrowsMaximize" : "tablerArrowsMinimize";
  }

  getFoldTooltip(): string {
    return this.data.getSettings().folded ? "Show All Ports" : "Hide Unconnected Ports";
  }

  getHeaderBackground(): string {
    if (!this.isValid()) {
      return "var(--vscode-errorForeground)";
    }

    if (this.ghActionTitle) {
      const color = this.data.getDefinition().style?.header?.background;
      if (color) {
        return HEADER_BACKGROUND_COLORS.get(color) || DEFAULT_HEADER_COLOR;
      }
    }
    return this.data.getDefinition().style?.header?.background || DEFAULT_HEADER_COLOR;
  }

  getBodyBackground(): string {
    if (!this.isValid()) {
      return "var(--vscode-errorForeground)";
    }

    if (this.ghActionTitle) {
      const color = this.data.getDefinition().style?.body?.background;
      if (color) {
        return BODY_BACKGROUND_COLORS.get(color) || DEFAULT_BODY_COLOR;
      }
    }
    return this.data.getDefinition().style?.body?.background || DEFAULT_BODY_COLOR;
  }

  onEditComment(event: Event): void {
    event.stopPropagation();
    event.preventDefault();

    this.contextMenu?.closeMenu();

    const node = this.data.getNodeModel();
    this.dialog.open(NodeCommentComponent, {
      panelClass: "custom-modal-dialog",
      width: "550px",
      height: "auto",
      disableClose: true,
      data: {
        comment: node.comment ?? "",
      },
    }).afterClosed().subscribe((result: NodeCommentData | null) => {
      if (result) {
        node.comment = typeof result.comment === "string" ? result.comment.slice(0, NodeCommentComponent.maxLength) : undefined;
        this.initComment();
        this.cdr.detectChanges();
      }
    });
  }

  onEditNodeInfo(event: Event): void {
    event.stopPropagation();
    event.preventDefault();

    this.contextMenu?.closeMenu();

    const node = this.data.getNodeModel();
    if (node.graph) {
      this.dialog.open(GroupNodeInfoComponent, {
        panelClass: "custom-modal-dialog",
        width: "550px",
        height: "auto",
        disableClose: true,
        data: {
          groupLabel: node.label ?? "",
          author: node.graph.info?.contact ?? "",
          contact: node.graph.info?.contact ?? "",
          version: node.graph.info?.version ?? "",
          description: node.graph.info?.description ?? "",
        },
      }).afterClosed().subscribe((result: GroupNodeInfoData | null) => {
        if (result) {
          node.label = result.groupLabel ?? node.label ?? "";
          if (node.graph) {
            node.graph.info = {
              author: result.author ?? node.graph.info?.author ?? "",
              contact: result.contact ?? node.graph.info?.contact ?? "",
              version: result.version ?? node.graph.info?.version ?? "",
              description: result.description ?? node.graph.info?.description ?? "",
            }
          }
          this.cdr.detectChanges();
        }
      });
    }
  }

  getNodeLabel(): string {
    if (this.isValid()) {
      return this.data.getNodeModel().label || this.data.getDefinition().label || this.data.getDefinition().name;
    } else {
      return this.data.getDefinition().id;
    }
  }

  getPermission(): Permission {
    return this.gs.getPermission();
  }

  showOutput(portId: string): boolean {
    if (this.data.getSettings().folded) {
      return this.data.isConnectedOrHasUserInput(portId);
    }

    return true;
  }

  showOutputGroupButtons(port: BaseOutput): boolean {
    return Boolean(port.def.array);
  }

  showInputGroupButtons(port: BaseInput): boolean {
    return (Boolean(port.def.array) || (port.isArrayPortOrHasArrayType && Boolean(port.control && port.showControl)));
  }

  showInputControl(port: BaseInput): boolean {
    return Boolean(!port.def.array && port.control && port.showControl);
  }

  showInputSocket(port: BaseInput): boolean {
    return !port.def.hide_socket && !port.def.array;
  }

  showOutputSocket(port: BaseOutput): boolean {
    return !(port.def.array && port.def.exec);
  }

  showInput(port: BaseInput): boolean {
    if (this.data.getSettings().folded) {
      return port.socket.isExec() || Boolean(port.control && port.showControl) || this.data.getInputValue(port.def.name) !== null;
    }

    return true;
  }

  isCompact(): boolean {
    return this.data.getDefinition().compact;
  }

  keepOrder(_a: unknown, _b: unknown): number {
    return 0; // This will keep the original order
  }

  getGroupNodeInfo(): string {
    const info = this.data.getNodeModel().graph?.info;
    if (info) {
      const author = info.author ?? "unknown";
      const description = info.description ?? "n/a";
      const version = info.version ?? "n/a";
      const contact = info.contact ?? "n/a";
      return `Author: ${author}\n\nDescription: ${description}\n\nVersion: ${version}\n\nContact: ${contact}`;
    } else {
      return "No Info Set";
    }
  }

  @HostListener("pointerdown", ["$event"])
  onPointerDown(event: PointerEvent): void {
    // By default, the node toolbar (the bar with the buttons) accepts all pointer events. We want to
    // stop simple clicks, while still accepting mousehover events since (to trigger mouseover/mouseout).
    // This will hide the comment tooltip.
    if ((event.target as HTMLDivElement).classList.contains("custom-toolbar")) {
      event.stopPropagation();
    }
  }

  @HostListener("mouseover", ["$event"])
  onMouseOverNode(event: MouseEvent): void {
    if (event.ctrlKey || event.metaKey) {
      if (!document.activeElement || !htmlIsUserInputField(document.activeElement)) {
        const connections = this.rs.getEditor().getConnections();
        for (const conn of connections) {
          conn.isTranslucent = this.data.id !== conn.source && this.data.id !== conn.target;
        }
      }
    } else if (!this.mouseover) {
      this.mouseover = true;
      this.cdr.detectChanges();

      if (this.tsInstance) {
        this.tsInstance.destroy();
        this.tsInstance = null;
      }
    }
  }

  @HostListener("mouseout", ["$event"])
  onMouseOutNode(_event: MouseEvent): void {
    const connections = this.rs.getEditor().getConnections();
    for (const conn of connections) {
      conn.isTranslucent = false;
    }

    if (this.mouseover) {
      this.mouseover = false;

      this.initComment(); // restore comment tooltip, if set

      this.cdr.detectChanges();
    }
  }

  dropOnDragZone(_event: MouseEvent): void {
    // don't intefer with node selection
    // event.preventDefault();
    // event.stopPropagation();

    this.gs.droppedOnGroupNode(this.data.id);
  }
}
